<template>
  <section class="section-1 bg-block mb-4">
    <h4 v-if="churchName !== ``">{{ churchName }}</h4>
    <h4 v-if="individualName !== ``">{{ individualName }}</h4>
    <h1 class="sm mb-4">
      <span v-if="!this.editedEmail.eml_key">{{ i18n['email-form'].tcAddEmail }}</span>
      <span v-if="!!this.editedEmail.eml_key">{{ i18n['email-form'].tcAddEmail }}</span>
    </h1>
    <div class="body">
      <b-form>
        <b-form-group class="form-element">
          <div class="g-row">
            <div class="g-col-1 d-flex">
              <b-form-group class="form-element">
                <h3 class="flex-1 mr-3 text-left font-style-2">{{ i18n['email-form'].tcIsPrimary }}</h3>
                <b-form-checkbox id="checkbox-1" v-model="editedEmail.primary_email_flag" name="checkbox-1"
                  :value="true" class="mb-3" :unchecked-value="false"></b-form-checkbox>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['email-form'].tcEmailAddress }}*</h3>
                <b-form-input id="type-text6" v-model="editedEmail.email_address" class="flex-0 mb-3 g-select"
                  type="text" maxlength="75"></b-form-input>
                <h3 class="flex-2 mr-3 text-left font-style-2">{{ i18n['email-form'].tcType }}*</h3>
                <b-form-select v-model="editedEmail.emt_key" :options="optionsEmailTypesTranslated"
                  class="form-control g-select flex-0 mr-3 mb-3">
                  <template slot="first"></template>
                </b-form-select>
              </b-form-group>
            </div>
          </div>
        </b-form-group>
      </b-form>
    </div>
    <div class="body">
      <div class="d-flex">
        <b-button @click="handleSaveClick" variant="primary" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
          i18n['email-form'].tcSave
        }}</b-button>
        <b-button @click="handleCancelClick" variant="tertiary" class="flex-0 w-100-sd mb-sm-0">{{
          i18n['email-form'].tcCancel
        }}</b-button>
      </div>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'email-form',
  mixins: [translationMixin],
  data() {
    return {
      editedEmail: {
        eml_key: '',
        emt_key: '',
        email_type: null,
        email_address: '',
        email_entity_key: '',
        email_invalid_flag: false,
        email_unlisted: false,
        primary_email_flag: false,
      },
      optionsEmailTypesTranslated: {},
    }
  },
  props: {
    churchName: {
      type: String,
      default: '',
    },
    churchKey: {
      type: String,
      default: '',
    },
    individualName: {
      type: String,
      default: '',
    },
    individualKey: {
      type: String,
      default: '',
    },
    locationName: {
      type: String,
      default: '',
    },
    locationKey: {
      type: String,
      default: '',
    },
    userKey: {
      type: String,
      default: '',
    },
    email: {
      type: Object,
      default: () => {
        return {
          eml_key: '',
          emt_key: '',
          email_type: null,
          email_address: '',
          email_entity_key: '',
          email_invalid_flag: false,
          email_unlisted: false,
          unlisted_word: '',
          primary_email_flag: false,
          primary_email_word: '',
          login_email: false,
        }
      },
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'email-form': {
            tcAddEmail: 'Add Email',
            tcCancel: 'Cancel',
            tcEditEmail: 'Edit Email',
            tcEmailAddress: 'Email Address',
            tcEmailSaved: 'The Email was saved.',
            tcErrorDuringSave: 'An error occurred during the save process. The Email was not saved.',
            tcErrorEmailNotValid: 'Email is not valid. Verify your address is entered correctly before attempting to save.',
            tcErrorEmailRequired: 'Email is required. Please complete before attempting to save.',
            tcErrorEmailTypeRequired: 'Type is required. Please complete before attempting to save.',
            tcIsPrimary: 'Is Primary?',
            tcOk: 'Ok',
            tcSave: 'Save',
            tcSelectType: 'Select a type',
            tcType: 'Type',
          },
        }
      },
    },
  },
  methods: {
    ...mapActions({
      loadEmailFormOptions: 'membership/loadEmailFormOptions',
      saveEmail: 'membership/saveEmail',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedEmail: 'user/setSelectedEmail',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey'
    }),
    async handleSaveClick() {
      if (this.editedEmail.email_address === '') {
        this.$swal({
          icon: 'error',
          text: this.i18n['email-form'].tcErrorEmailRequired,
          confirmButtonText: this.i18n['email-form'].tcOk,
        }).then((result) => {
          return
        })
      } else if (
        !this.editedEmail.email_address.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ) {
        // Regex to match valid email addresses. Source: https://www.w3resource.com/javascript/form/email-validation.php
        this.$swal({
          icon: 'error',
          text: this.i18n['email-form'].tcErrorEmailNotValid,
          confirmButtonText: this.i18n['email-form'].tcOk,
        }).then((result) => {
          return
        })
      } else if (this.editedEmail.emt_key === null) {
        this.$swal({
          icon: 'error',
          text: this.i18n['email-form'].tcErrorEmailTypeRequired,
          confirmButtonText: this.i18n['email-form'].tcOk,
        }).then((result) => {
          return
        })
      } else {
        var payload = {
          allow_confirm_primary_procedure_run: false,
          eml_address: this.editedEmail.email_address,
          eml_delete_flag: false,
          eml_emt_key: this.editedEmail.emt_key,
          eml_entity_key: !!this.individualKey ? this.individualKey : this.churchKey,
          eml_invalid_flag: this.editedEmail.email_invalid_flag,
          eml_key: this.editedEmail.eml_key,
          eml_unlisted_flag: this.editedEmail.email_unlisted,
          eml_user: this.userKey,
          member_no: this.demograph.member_number,
          primary_email_flag: this.isPrimaryFlag
        }

        let result = true
        try {
          await Promise.all([this.setLoadingStatus(true), (result = await this.saveEmail(payload))]).then(() => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: result ? 'success' : 'error',
              text: result ? this.i18n['email-form'].tcEmailSaved : this.i18n['email-form'].tcErrorDuringSave,
              confirmButtonText: this.i18n['email-form'].tcOk,
            }).then((result) => {
              if (result.value) {
                this.handleCancelClick()
              }
            })
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async handleCancelClick() {
      await Promise.all([await this.setSelectedEmail({})]).then(() => {
        this.$router.go(-1)
      })
    },
  },
  computed: {
    ...mapGetters({
      churchCommunicationsData: 'churchMinistry/churchCommunicationsData',
      convertedEmailFormOptions: 'membership/convertedEmailFormOptions',
      demograph: 'user/demograph',
      emailFormOptions: 'membership/emailFormOptions',
      individualCommunicationsData: 'membership/individualCommunicationsData',
      locationCommunicationsData: 'scriptureDistribution/locationCommunicationsData',
      userIndKey: 'user/userId'
    }),
    isPrimaryFlag() {
      return this.editedEmail.primary_email_flag === true ||
        (
          !!this.individualName &&
          this.individualCommunicationsData.emails &&
          this.individualCommunicationsData.emails.length === 0
        ) ||
        (
          !!this.churchName &&
          this.churchCommunicationsData.emails &&
          this.churchCommunicationsData.emails.length === 0
        ) ||
        (
          !!this.locationName &&
          this.locationCommunicationsData.emails &&
          this.locationCommunicationsData.emails.length === 0
        )
    },
    // FormOptions
    optionsEmailTypes() {
      return [...this.convertedEmailFormOptions.emailTypes]
    },
    // END FormOptions
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      if (this.emailFormOptions.emailTypes.length === 0) {
        await Promise.all([this.loadEmailFormOptions()])
      }
      this.editedEmail.eml_key = !!this.email.eml_key ? this.email.eml_key : ''
      this.editedEmail.emt_key = !!this.email.emt_key ? this.email.emt_key : null
      this.editedEmail.email_type = !!this.email.email_type ? this.email.email_type : ''
      this.editedEmail.email_address = !!this.email.email_address ? this.email.email_address : ''
      this.editedEmail.email_invalid_flag = !!this.email.email_invalid_flag ? this.email.email_invalid_flag : false
      this.editedEmail.email_unlisted = !!this.email.email_unlisted ? this.email.email_unlisted : false
      this.editedEmail.primary_email_flag = !!this.email.primary_email_flag

      const firstOption = this.i18n['email-form'].tcSelectType || 'Select Type'
      this.translateDropdownOptions(
        [{ node: 'email-types', store: 'optionsEmailTypes', disabledPlaceholder: firstOption }],
        'i18n'
      )
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
